import React from "react";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useMediaQuery } from "react-responsive";

import graduation from "../../assets/sliderImages/A3.jpg";
import melbourne from "../../assets/sliderImages/A1.jpg";
import aphabet from "../../assets/sliderImages/A4.jpg";
import opera from "../../assets/sliderImages/A2.jpg";

const images = [opera, graduation, aphabet, melbourne];
// make the ZoomSlideshow responsive
const ZoomSlideshow = () => {
  const isMobileOnly = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <div className="slide-container mt-5 mt-lg-4 customSlider">
      <Zoom scale={0.5}>
        {images.map((each, index) => (
          <img
            key={index}
            style={
              isMobileOnly
                ? { width: "100%", height: 200, marginTop: 30 }
                : { width: "100%", height: 400, marginTop: 50 }
            }
            alt={index + "img Slider"}
            src={each}
          />
        ))}
      </Zoom>
    </div>
  );
};

export default ZoomSlideshow;
