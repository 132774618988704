import React from "react";
import LivingcostInAustralia from "../LivingcostInAustralia";
import EducationalOpportunitiesforInternationalStudents from "../EducationalOpportunitiesforInternationalStudents";
import "../../App.css";

export default function LivingCost() {
  return (
    <div>
      {/* <NavComponent /> */}
      <main className="nav-margin-bottom">
        <LivingcostInAustralia />
        <EducationalOpportunitiesforInternationalStudents />
      </main>
      {/* <FooterOne /> */}
    </div>
  );
}
