import Accordion from 'react-bootstrap/Accordion';

function WhyStudyInAus() {
  return (
    <div className="container">
    <div style={{ textAlign: 'left', margin:15 }}>
    <h2 className='text-black'>Why study in Australia?</h2>
    <p>Many people want to study in Australia -English is the merely one language in
Australia -which is significant important from people Indian sub-continent, Sri Lanka.
Student legally allowed do parttime work while they are studying. There are large
numbers of students don’t bring money from home country, able to afford
educational expenses, accommodation all other expenditure. To fulfil their dream,
they must work and study hard but many students are doing so. There are
opportunities for brilliant student to apply for scholarship. 2023-2024 academic year
Universities in Australia wide will more than 1000 scholarships. These scholarships also
cover tuition fees, accommodation allowance, health insurance, travel allowance and
monthly allowance. After completion of their course, they may eligible to apply
permanent residency. There are plenty jobs for student. Economically this country is
strong position, unemployment rate is significantly low which is currently 3.50%.</p>
<p>Geographically it is close to Asia, great climate, relatively crimeless and peaceful
country.</p>
<p>Admission process is comparatively easier, educational institutes are well equipped
with the latest technologies and world-class education system.</p>
    <p>There are compelling reasons why Australia is often considered a good choice for
international students:</p>
</div>

    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Quality Education</Accordion.Header>
        <Accordion.Body>
        Australia is home to several top-ranked universities
and offers high-quality education across various disciplines.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Diverse Culture</Accordion.Header>
        <Accordion.Body>
        The country embraces multiculturalism, providing an
opportunity to engage with people from various backgrounds and cultures.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Research Opportunities:</Accordion.Header>
        <Accordion.Body>
        Australia has a strong research community and
offers opportunities for students to engage in cutting-edge research.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Beautiful Environment</Accordion.Header>
        <Accordion.Body>
        Stunning landscapes, beaches, and natural
wonders make Australia an attractive destination for adventure and leisure.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Safety and Healthcare</Accordion.Header>
        <Accordion.Body>
        The country is known for its safety, well-
functioning healthcare system, and quality of life.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Part-time Work</Accordion.Header>
        <Accordion.Body>
        International students are allowed to work part-time
while studying, helping them gain work experience and support their
expenses.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Post-Study Work Options</Accordion.Header>
        <Accordion.Body>
        Australia offers post-study work visas that
allow students to stay and work in the country after graduation.
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
    </div>
  );
}

export default WhyStudyInAus;