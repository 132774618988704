import React from "react";
import ZoomSlideshow from "../sliders/ZoomEffect";
import Services from "../services";
import About from "../About";
import WhyChoose from "../WhyChooseUs";
import LogoSlider from "../sliders/LogoSlider";

export default function Home() {
  return (
    // We dont use div inside div which is bad coding practice, ranther use Fragment like below
    <>
      {/* <NavComponent /> */}
      <ZoomSlideshow />
      <Services />
      <About />
      <WhyChoose />
      <LogoSlider />
      {/* <FooterOne /> */}
    </>
  );
}
