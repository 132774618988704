import React from "react";
import VisaProcessForInternationalStudent from "../VisaProcessForInternationalStudent";
import WhyStudyInAus from "../WhyStudyInAus";
import "../../App.css";

export default function WhyAustralia() {
  return (
    <>
      {/* <NavComponent /> */}
      <main className="nav-margin-bottom">
        <WhyStudyInAus />
        <VisaProcessForInternationalStudent />
      </main>
      {/* <FooterOne /> */}
    </>
  );
}
