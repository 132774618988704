import React, { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../App.css";

export default function EducationConsultancy() {
  return (
    <Fragment>
      {/* <NavComponent /> */}
      <main id="main" className="nav-margin-bottom">
        <div className="container mb-5">
          <h2 className="text-center mt-3 text-primary">
            ADMISSION AID SERVICES
          </h2>
          <hr />
          <p className="text-center">
            We provide a comprehensive support from searching an appropriate
            subject area, university, and getting an admission to fulfill your
            dream.
          </p>

          <h5 className="text-center"> Our admission aid supports include:</h5>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Admission Assistance:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Guidance on choosing the right academic program and
                    institution based on the student's goals and preferences.
                  </li>
                  <li>
                    Assistance with the application process, including
                    documentation and application submission.
                  </li>
                  <li>
                    Expert advice on meeting admission requirements and
                    deadlines.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Language Proficiency Assistance:
              </Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Recommendations for language proficiency tests (e.g., IELTS,
                    TOEFL) required for admission.
                  </li>
                  <li>
                    Preparation resources and guidance to achieve the necessary
                    language scores.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Visa Counseling and VISA processing:{" "}
              </Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Comprehensive support in the visa application process,
                    ensuring compliance with immigration regulations.
                  </li>
                  <li>
                    Assistance in preparing and organizing visa documentation.
                  </li>
                  <li>
                    Processing student visa, family visa, tourist visa, skill
                    migration visa
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Financial Aid Counseling:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Information on available scholarships, grants, and financial
                    aid opportunities.
                  </li>
                  <li>
                    Assistance in preparing scholarship applications and
                    required documentation.
                  </li>
                  <li>
                    Advice on budgeting and managing finances during the study
                    period.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Document Verification:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Thorough review of academic and personal documents to ensure
                    they meet the admission and visa requirements.
                  </li>
                  <li>
                    Assistance in obtaining and verifying transcripts,
                    recommendation letters, and other necessary documents.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Orientation Services:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Pre-departure orientation sessions for students, providing
                    information on the destination country, culture, and
                    academic expectations.
                  </li>
                  <li>
                    Assistance with travel arrangements and accommodation.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Post-Arrival Support:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Assistance in acclimating to the new environment, including
                    local orientation and support services.
                  </li>
                  <li>
                    Ongoing support for any issues related to academics, visa
                    compliance, or personal matters.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Career Counseling:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Guidance on career paths and opportunities available after
                    completing the academic program.
                  </li>
                  <li>
                    Assistance in connecting with potential employers or
                    networking events.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Updates and Communication:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Regular updates on the status of applications and visa
                    processes.
                  </li>
                  <li>
                    Prompt communication regarding any changes in immigration
                    policies or academic requirements.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Customized Services:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Tailored services based on individual needs and preferences.
                  </li>
                  <li>
                    Flexibility to address specific challenges or requirements
                    unique to each student.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </main>
      {/* <FooterOne /> */}
    </Fragment>
  );
}
