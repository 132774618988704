import React, { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../App.css";

export default function MigrationServices() {
  return (
    <Fragment>
      {/* <NavComponent /> */}
      <main className="nav-margin-bottom">
        <div className="container mb-5">
          <h2 className="text-center mt-3 text-primary">
            SKILL MIGRATION SERVICES
          </h2>
          <hr />
          <p className="text-center">
            We are dedicated to guiding you through every aspect of your
            migration process.
          </p>

          <h5 className="text-center"> Our migration services include:</h5>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Skill Assessment:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Assistance in evaluating and assessing the individual's
                    skills, qualifications, and work experience.
                  </li>
                  <li>
                    Guidance on choosing the appropriate skill assessment
                    authority for the specific migration destination.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Occupation Matching:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Identification of occupations in demand in the target
                    country based on the individual's skills and qualifications.
                  </li>
                  <li>
                    Assistance in selecting an occupation that aligns with the
                    individual's expertise and the needs of the destination
                    country.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Points Calculation:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Evaluation of eligibility based on points systems used for
                    skilled migration.
                  </li>
                  <li>
                    Detailed analysis of how different factors, such as age,
                    language proficiency, and work experience, contribute to the
                    overall points score.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Documentation Support:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Guidance on preparing and organizing the necessary
                    documentation for the skill migration application.
                  </li>
                  <li>
                    Assistance in obtaining certified copies of educational
                    certificates, professional licenses, and other required
                    documents.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Application Preparation:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Support in completing and submitting the skill migration
                    application accurately and within specified deadlines.
                  </li>
                  <li>
                    Review of application materials to ensure compliance with
                    immigration requirements.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Language Proficiency Assistance:
              </Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Recommendations for language proficiency tests (e.g., IELTS,
                    TOEFL) if required for migration.
                  </li>
                  <li>
                    Preparation resources and guidance to achieve the necessary
                    language scores.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Job Search Assistance:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Strategies for job searching in the destination country,
                    including resume building and cover letter writing.
                  </li>
                  <li>
                    Networking advice and connections with potential employers
                    or recruitment agencies.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Pre-Departure Briefing:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Information and advice on the cultural, social, and
                    professional aspects of living and working in the new
                    country.
                  </li>
                  <li>
                    Assistance with travel arrangements and accommodation.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Legal and Immigration Compliance:
              </Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Guidance on understanding and complying with immigration
                    laws and regulations.
                  </li>
                  <li>
                    Regular updates on changes in immigration policies affecting
                    skilled migration.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Post-Arrival Support:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Ongoing support for individuals after they have migrated,
                    including assistance with settling into the new environment.
                  </li>
                  <li>
                    Information on local resources, services, and communities
                    that can aid in the transition.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Continuous Updates:</Accordion.Header>
              <Accordion.Body>
                <ul className="ml-4">
                  <li>
                    Regular updates on the status of the migration application.
                  </li>
                  <li>
                    Timely communication regarding any changes in immigration
                    policies or procedures.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </main>
      {/* <FooterOne /> */}
    </Fragment>
  );
}
