import "../styles/googleMapStyle.css";
// import ErrorBoundary from "./ErrorBoundary";

const GoogleMap = () => {
  return (
    <>
      <div className="responsive-map rounded">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d606.8749951325415!2d144.96773081519143!3d-37.711939217578106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6452e25b43893%3A0x2427fce7e5f6ef38!2s48A%20Mutton%20Rd%2C%20Fawkner%20VIC%203060!5e0!3m2!1sen!2sau!4v1711289832799!5m2!1sen!2sau"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default GoogleMap;
