import { Container, Col, Row, Image } from "react-bootstrap";
import aid from "../images/Abou/istockphoto-186358917-612x612.jpg";

const About = () => {
  return (
    <Container>
      <hr />
      <Row>
        <Col s={12} md={6} className="mt-auto mb-auto rounded">
          <Image src={aid} width="100%" height="100%" />
        </Col>
        <Col s={12} md={6}>
          <h2 className="featurette-heading text-center mt-2">
            <span className="fw-bold">ABOUT GEMC</span>
          </h2>
          <hr />
          <p className="fs-6 px-2 text-justify" style={{ textAlign: 'justify' }}>
            Global Education and Migration Consultant (GEMC), Australia, ACN:
            672527730, was founded in 2023 with a dedicated mission to assist
            students from diverse corners of the world. Our professional
            consulting services in education and migration aim to provide
            comprehensive counseling and information to international students,
            facilitating their academic and professional endeavors. GEMC is
            committed to deliver high-quality services to students and skilled
            professionals through offering reliable information in the most
            professional manner. The continuous growth of GEMC can be attributed
            to the positive feedback and appreciation received from students and
            professionals. Our experienced staff ensures the provision of
            consistent and efficient support, reflecting GEMC's commitment to
            excellence.
          </p>
          <p className="fs-6 px-2 text-justify" style={{ textAlign: 'justify' }}>
            At the heart of our success lies a realistic approach to addressing
            the unique needs and demands of our clients. This commitment extends
            to our partnerships with educational institutions, solidifying our
            position as a reputable education agent in Australia. We are poised
            and ready to recognize and respond to the evolving needs of our
            clients, ensuring that GEMC continues to be a trusted ally for those
            aspiring to build a brighter future through education and migration
            opportunities in Australia.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
