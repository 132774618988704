import React from 'react'

export default function LivingcostInAustralia() {


    const listStyle = {
        listStyleType: 'none', // Remove bullet points
        paddingLeft: 0, // Remove left padding for the list
      };
      const listItemStyle = {
        paddingBottom: '5px', // Add 5px of padding after each list item
      };
  return (
  
   <div className='container'>
    <h2 className='text-black'>Living cost in Australia</h2>
    <div style={{ textAlign: 'left', margin:15 }}>
    <p>The cost of living for international students in Australia can vary based on factors such as location, lifestyle, and personal choices. Here are some estimated average expenses to consider when planning your budget:</p>
    <ul style={listStyle}>
      <li style={listItemStyle}>
        <strong>Accommodation: </strong> The cost of housing can vary significantly depending on whether you choose to live on-campus, off-campus, or in shared accommodation. On average, you might expect to pay around AUD $140 to $250 per week for shared accommodation and AUD $250 to $600 per week for private rentals, depending on the city.
      </li>
      <li style={listItemStyle}>
        <strong>Food: </strong> Budget around AUD $80 to $140 per week for groceries if you cook your own meals. Eating out or ordering takeout can cost around AUD $10 to $20 for a basic meal. 
      </li>
      <li style={listItemStyle}>
        <strong>Transportation: </strong> Public transportation costs can vary by city. On average, monthly public transportation passes might cost around AUD $100 to $150. If you plan to use a car, consider expenses like fuel, insurance, and parking. 
      </li>
      <li style={listItemStyle}>
        <strong>Health Insurance: </strong> Overseas Student Health Cover (OSHC) is mandatory for international students. Costs can vary based on your chosen provider, coverage, and duration of study. Budget around AUD $450 to $700 per year. 
      </li>
      <li style={listItemStyle}>
        <strong>Study Materials: </strong> Textbooks and study materials can cost around AUD $500 to $800 per year, depending on your course. 
      </li>
      <li style={listItemStyle}>
        <strong>Personal Expenses: </strong> This includes mobile phone bills, internet, entertainment, and other personal expenses. Budget around AUD $50 to $90 per week. 
      </li>
      <li style={listItemStyle}>
        <strong>Miscellaneous Costs: </strong> This can include expenses such as clothing, toiletries, and other incidentals. Budget around AUD $20 to $40 per week.
      </li>
      
    </ul>

    <p>
    It's important to research and plan your budget carefully to ensure you have enough funds to cover your living expenses throughout your stay. Keep in mind that cities like Sydney and Melbourne tend to have higher living costs compared to other cities.
    </p>


    <p>
    Also, check if your chosen institution provides any cost-of-living estimates or resources to help you plan your budget more accurately. Always have some extra funds for unexpected expenses and emergencies.
    </p>
  </div>
  </div>
  )
}
