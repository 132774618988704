import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.text}>
        Sorry, the page you are looking for does not exist.
      </p>
      <p style={styles.text}>
        You can return to the{" "}
        <Link to="/" style={styles.link}>
          homepage
        </Link>
        .
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "150px",
    fontFamily: "Arial, sans-serif",
  },
  heading: {
    fontSize: "36px",
    color: "#333",
    marginBottom: "20px",
  },
  text: {
    fontSize: "18px",
    color: "#666",
    marginBottom: "10px",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
  },
};

export default NotFoundPage;
