import Container from "react-bootstrap/Container";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import logo from "../assets/logo/logo.jpeg";

const Styles = styled.div`
  .navbar-toggler {
    border-color: gray;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2.5' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-brand,
  .navbar-nav .nav-item .nav-link {
    color: #fff;
    &:hover {
      color: #fb7840;
    }
  }
  #basic-navbar-nav a:hover {
    background-color: rgb(15, 70, 120);
    color: rgb(250, 250, 251);
    border-radius: 10px;
    transform: scale(1.1);
  }
`;

function NavComponent() {
  const handleNavLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Styles>
      <Navbar
        id="nav"
        expand="sm"
        variant="#fff"
        collapseOnSelect // Add collapseOnSelect prop here
        fixed="top"
        style={{
          backgroundColor: "#013365",
          color: "#fff",
        }}
      >
        <Container>
          <Navbar.Brand
            style={{
              fontSize: "24px",
              fontWeight: "10em",
              color: "navajowhite",
            }}
          >
            <LinkContainer to="/">
              <img
                src={logo}
                width="45"
                height="45"
                className="d-inline-block align-top"
                alt="GEMC logo"
              />
            </LinkContainer>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="mr-auto">
              {" "}
              {/* Move Nav component here */}
              <LinkContainer to="/" className="p-3 text-white">
                <Nav.Link onClick={handleNavLinkClick}>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/WhyAustralia" className="p-3 text-white">
                <Nav.Link onClick={handleNavLinkClick}>
                  Study In Australia
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/LivingCost" className="p-3 text-white">
                <Nav.Link onClick={handleNavLinkClick}>
                  Expenses In Australia
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/ContactUs" className="p-3 text-white">
                <Nav.Link onClick={handleNavLinkClick}>Contact us</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Styles>
  );
}

export default NavComponent;
