import { useMediaQuery } from "react-responsive";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import aid from "../images/service/2231642.png";
import skm from "../images/service/skillMigration.jpg";
import vp from "../images/service/6554686.png";
const Services = () => {
  const navigate = useNavigate();
  const isMobileOnly = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-lg-12 mb-1 d-flex align-items-center justify-content-center text-center">
          <h2 className="bold text-success">
            Global Education and Migration Consultant
          </h2>
        </div>
        <hr />
        <div className="col-lg-12 d-flex align-items-center justify-content-center text-center">
          <h2 className="fw-bolder">CORE SERVICES</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 mt-1 d-flex justify-content-center align-items-center flex-column">
            <svg
              className="bd-placeholder-img rounded"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="true"
            >
              <title>Admission Aid</title>

              <image href={aid} width="100%" height="100%" />
            </svg>

            <h2 className={isMobileOnly ? "fw-bold mt-4" : "text-muted mt-4"}>
              Admission Aid
            </h2>
            <p
              className="fs-6 mt-2 mx-2 text-justify"
              style={{ textAlign: "justify" }}
            >
              We provide a comprehensive support from searching an appropriate
              subject area, university, and getting an admission to fulfill your
              dream.
            </p>
            <div
              className="p-2"
              onClick={(event) => {
                event.preventDefault();
                navigate("/AdmissionAid");
                window.scrollTo(0, 0);
              }}
            >
              <Button>View details</Button>
            </div>
          </div>
          <div className="col-lg-4 mt-1 d-flex justify-content-center align-items-center flex-column">
            <svg
              className="bd-placeholder-img rounded"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Skill Migration</title>
              <image href={skm} width="100%" height="100%" />
            </svg>

            <h2 className={isMobileOnly ? "fw-bold mt-4" : "text-muted mt-4"}>
              Skill Migration
            </h2>
            <p
              className="fs-6 mt-2 mx-2 text-justify"
              style={{ textAlign: "justify" }}
            >
              We are dedicated to guiding you through every aspect of your
              migration process. Get benetfitted with one to one consulation
              session.
            </p>

            <div
              className="p-2"
              onClick={(event) => {
                event.preventDefault();
                navigate("/SkillMigration");
                window.scrollTo(0, 0);
              }}
            >
              <Button>View details</Button>
            </div>
          </div>
          <div className="col-lg-4 mt-1 d-flex justify-content-center align-items-center flex-column">
            <svg
              className="bd-placeholder-img rounded"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title className={isMobileOnly ? "fs-6" : undefined}>
                Visa Processing
              </title>

              <image href={vp} width="100%" height="100%" />
            </svg>

            <h2 className={isMobileOnly ? "fw-bold mt-4" : "text-muted mt-4"}>
              Visa Processing
            </h2>
            <p
              className="fs-6 mt-2 mx-2 text-justify"
              style={{ textAlign: "justify" }}
            >
              Our consultancy provides comprehensive assistance at every stage
              of the visa application process, increasing your chances of a
              successful outcome.
            </p>

            <div
              className="p-2"
              onClick={(event) => {
                event.preventDefault();
                navigate("/VisaProcessing");
                window.scrollTo(0, 0);
              }}
            >
              <Button>View details</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
