// import imgOne from "../images/1.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMediaQuery } from "react-responsive";

const WhyChoose = () => {
  const isMobileOnly = useMediaQuery({ query: "(max-width: 900px)" });
  // const bgImage = {
  //   backgroundImage: imgOne,
  // };
  return (
    <div
      className="text-secondary px-3 py-1 text-center bgImage"
      style={{ backgroundColor: "#7E8679" }}
    >
      <div className="py-5">
        <h1 className="display-6 fw-bold text-white pb-3">WHY GEMC</h1>
        {isMobileOnly ? (
          <div className="col-lg-8 mx-auto text-white">
            <p className="fs-6 mb-2" style={{ color: "#fff" }}>
              Our team of experts boasts extensive experience in the education
              sector, having pursued higher education degrees (BSc, Masters, and
              PhD) in Australia. Our proficiency extends to skill migration and
              Global Talent visas, reflecting our commitment to facilitating
              seamless transitions for individuals seeking educational and
              professional opportunities in Australia.
            </p>
            <p className="fs-6 mb-2" style={{ color: "#fff" }}>
              Having served as university teachers/lecturers in Australia, we
              have gained an in-depth understanding of the Australian education
              system and admission processes. Our firsthand experience allows us
              to provide comprehensive guidance to aspiring students. Currently
              residing in Australia, we are actively engaged in both
              Professional and Social communities, enabling us to offer
              unparalleled support in navigating and choosing a career path that
              aligns with your aspirations.
            </p>
            <p className="fs-6 mb-2" style={{ color: "#fff" }}>
              Furthermore, our expertise extends beyond academia, as we are
              well-versed in the intricacies of the Australian job market,
              cultural nuances, and social dynamics. This unique insight
              positions us to guide you not only in your educational pursuits
              but also in securing employment opportunities swiftly upon your
              arrival in Australia.
            </p>
            <p className="fs-6 mb-2" style={{ color: "#fff" }}>
              Moreover, our team's collective experience spans across diverse
              geographical regions, including Australia, South Asia, and the
              Middle East. This broad perspective equips us with a global
              understanding of education and professional landscapes, allowing
              us to provide comprehensive and tailored assistance to individuals
              from various backgrounds.
            </p>
            <p className="fs-6 mb-2" style={{ color: "#fff" }}>
              In choosing our services, you are not only benefiting from our
              academic expertise but also from our commitment to your holistic
              success, ensuring a smooth transition into the Australian
              educational and professional domains.
            </p>
          </div>
        ) : (
          <Row>
            <Col className="d-flex align-self-center">
              <p className="fs-6 mb-2" style={{ color: "#fff" }}>
                Our expert team boasts extensive experience in the education
                sector, having higher degrees (BSc, Masters, and PhD) in
                Australia.
                <br /> <br /> Our proficiency extends to skill migration and
                Global Talent visas, reflecting our commitment to facilitating
                seamless transitions for individuals seeking educational and
                professional opportunities in Australia.
              </p>
            </Col>
            <Col className="d-flex align-self-center">
              <p className="fs-6 mb-2" style={{ color: "#fff" }}>
                Having served as university teachers in Australia, we have
                gained an in-depth understanding of the Australian education
                system and admission processes.
                <br /> <br /> Our firsthand experience in education and
                professional industries allows us to provide comprehensive
                guidance to aspiring students.
              </p>
            </Col>
            <Col className="d-flex align-self-center">
              <p className="fs-6 mb-2" style={{ color: "#fff" }}>
                As Australian residents, we are actively engaged in both
                Professional and Social communities, which enabling us to offer
                unparallel support for you in navigating and choosing a career
                path into Australian job market considering local culture and
                social dynamics. <br /> <br />
                This unique insight positions us to guide you not only in your
                educational pursuits but also in securing employment
                opportunities soon after your arrival in Australia.
              </p>
            </Col>
            <Col className="d-flex align-self-center">
              <p className="fs-6 mb-2" style={{ color: "#fff" }}>
                Our team's collective experience spans diverse geographical
                regions, including Australia, South Asia, and the Middle East.
                <br /> <br />
                This broader perspective equips us with a global understanding
                of education and professional landscapes, enabling us to provide
                comprehensive and tailored assistance to individuals from
                various backgrounds.
              </p>
            </Col>
            <Col className="d-flex align-self-center">
              <p className="fs-6 mb-2" style={{ color: "#fff" }}>
                In choosing our services, you are not only benefiting from our
                academic expertise but also from our commitment to your holistic
                success, ensuring a smooth transition into the Australian
                educational and professional domains.
              </p>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default WhyChoose;
