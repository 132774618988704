import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../App.css";

//  This is visa processing page
export default function PostArrivalSupport() {
  return (
    <div className="nav-margin-bottom">
      {/* <NavComponent /> */}
      {/* <main> */}
      <div className="container mb-5">
        <h2 className="text-center mt-3 text-primary">
          VISA PROCESSING SERVICES
        </h2>
        <hr />
        <p className="text-center">
          Our consultancy provides comprehensive assistance at every stage of
          the visa application process, increasing your chances of a successful
          outcome.
        </p>

        <h5 className="text-center">
          Our visa processing supports include but not limited to:
        </h5>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Initial Consultation:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  In-depth discussion to understand the client's visa needs,
                  including the purpose of travel, destination, and type of visa
                  required.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Visa Category Selection:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Assistance in determining the most appropriate visa category
                  based on the client's purpose, such as tourist, student, work,
                  or family reunification visas.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Documentation Guidance:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Comprehensive checklist of required documents for the chosen
                  visa category.
                </li>
                <li>
                  Detailed guidance on obtaining and organizing necessary
                  documentation, including any notarization or authentication
                  requirements.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Application Form Assistance:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Support in completing and reviewing visa application forms to
                  ensure accuracy and completeness.
                </li>
                <li>
                  Guidance on answering specific questions and addressing
                  potential pitfalls.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Visa Fee Handling:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Assistance in understanding and managing visa fees, including
                  any associated processing or service charges.
                </li>
                <li>
                  Guidance on payment methods and ensuring proper documentation
                  of transactions.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Appointment Scheduling:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Assistance in scheduling appointments with the appropriate
                  visa application centers or consulates.
                </li>
                <li>
                  Providing information on the location, timing, and
                  requirements for the visa application submission.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Biometric Enrollment Assistance:
            </Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Guidance on the biometric data collection process, including
                  fingerprints and photographs.
                </li>
                <li>
                  Ensuring clients are prepared for and informed about the
                  biometric enrollment appointment.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>Communication with Authorities:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Regular follow-ups with visa processing authorities to track
                  the status of the application.
                </li>
                <li>
                  Addressing any additional requests for information or
                  documentation promptly.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>Interview Preparation:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Coaching and guidance for visa interviews, including potential
                  questions and appropriate responses.
                </li>
                <li>
                  Mock interviews to help clients feel confident and prepared.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>Visa Collection Support:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Assistance in collecting the visa once approved, including
                  instructions on where and how to retrieve the visa document.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>Continuous Updates:</Accordion.Header>
            <Accordion.Body>
              <ul className="ml-4">
                <li>
                  Regular updates on the progress of the visa application.
                </li>
                <li>
                  Timely communication regarding any changes in visa policies or
                  procedures.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {/* </main> */}
      {/* <FooterOne /> */}
    </div>
  );
}
