import React from "react";
import "./LogoSlider.css";
import cqu from "../../images/logo/cqu.png";
import fedUni from "../../images/logo/FedUni.jpeg";
import jamesCook from "../../images/logo/james-cook.png";
import laTrobe from "../../images/logo/la-trobe.png";
import notreDame from "../../images/logo/notre-dame.png";
import rmit from "../../images/logo/RMIT.png";
import swinburne from "../../images/logo/swinburne.png";
import torrens from "../../images/logo/torrens.png";
import usc from "../../images/logo/usc.png";
import usq from "../../images/logo/usq.png";
import une from "../../images/logo/une.png";
import victoria from "../../images/logo/victoria-uni.png";

const LogoSlider = () => {
  return (
    <div className="slider mt-5 mb-5">
      <div className="slide-track">
        <div className="slide">
          <img src={cqu} height="100" width="200" alt="" />
        </div>
        <div className="slide">
          <img src={fedUni} height="100" width="200" alt="" />
        </div>
        <div className="slide">
          <img src={jamesCook} height="100" width="200" alt="" />
        </div>
        <div className="slide">
          <img src={laTrobe} height="100" width="150" alt="" />
        </div>
        <div className="slide">
          <img src={notreDame} height="100" width="100" alt="" />
        </div>
        <div className="slide">
          <img src={rmit} height="100" width="200" alt="" />
        </div>
        <div className="slide">
          <img src={swinburne} height="100" width="200" alt="" />
        </div>
        <div className="slide">
          <img src={torrens} height="100" width="200" alt="" />
        </div>
        <div className="slide">
          <img src={une} height="100" width="150" alt="" />
        </div>
        <div className="slide">
          <img src={usc} height="100" width="150" alt="" />
        </div>
        <div className="slide">
          <img src={usq} height="100" width="150" alt="" />
        </div>
        <div className="slide">
          <img src={victoria} height="100" width="200" alt="" />
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
