import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/pages/Home";
import WhyAustralia from "./components/pages/WhyAustralia";
import LivingCost from "./components/pages/LivingCost";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import EducationConsultancy from "./components/pages/EducationConsultation";
import MigrationServices from "./components/pages/MigrationServices";
import PostArrivalSupport from "./components/pages/PostArrivalSupport";
import NavComponent from "./components/NavComponent";
import FooterOne from "./components/Footer/FooterOne";
import ContactUs from "./components/pages/ContactUs";
import NotFoundPage from "./components/pages/NotFoundPage";

// Define an array of paths where you want to show the footer
const footerVisiblePaths = [
  "/",
  "/WhyAustralia",
  "/LivingCost",
  "/ContactUs",
  "/AdmissionAid",
  "/SkillMigration",
  "/VisaProcessing",
];

const FooterWrapper = () => {
  const location = useLocation();

  const shouldShowFooterOne = footerVisiblePaths.includes(location.pathname);

  return <>{shouldShowFooterOne && <FooterOne />}</>;
};

const App = () => {
  return (
    // Standard practice is declare all routes here, we can wrap Nav as below commented line but I am getting error. Try to fix if you can
    // React router is not react package it is a 3rd party package. Read this https://reactrouter.com/en/6.16.0
    <BrowserRouter>
      <NavComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/WhyAustralia" element={<WhyAustralia />} />
        <Route path="/LivingCost" element={<LivingCost />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/AdmissionAid" element={<EducationConsultancy />} />
        <Route path="/SkillMigration" element={<MigrationServices />} />
        <Route path="/VisaProcessing" element={<PostArrivalSupport />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <FooterWrapper />
    </BrowserRouter>
  );
};

export default App;
